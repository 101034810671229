import {
  sendSignInLinkToEmail,
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  isSignInWithEmailLink,
  IdTokenResult,
} from 'firebase/auth';
import asyncRetry from 'async-retry';
import { patchFetcher, postFetcher } from '@s/io/swrFetcher';
import { apiEndpoint } from '@c/config';
import { genericError } from '@c/application/GenericError';

export async function inviteOperator(
  url: string,
  { arg }: { arg: { email: string; roleType: string } }
) {
  await postFetcher(url, {
    email: arg.email,
    operator_role_type: arg.roleType,
  });

  await sendSignInLinkToEmail(getAuth(), arg.email, {
    url: `${location.protocol}//${location.host}/register-operator`,
    handleCodeInApp: true,
  });
}

export async function updateOperator(
  url: string,
  { arg }: { arg: { displayName: string; roleType: string } }
) {
  await patchFetcher(url, {
    display_name: arg.displayName,
    operator_role_type: arg.roleType,
  });
}

export async function updateOperatorDisabled(
  url: string,
  { arg }: { arg: { isDisabled: boolean } }
) {
  await patchFetcher(url, {
    disabled: arg.isDisabled,
  });
}

export async function issueOperator({
  password,
  email,
  displayName,
}: {
  password: string;
  email: string;
  displayName: string;
}): Promise<void> {
  if (!isSignInWithEmailLink(getAuth(), location.href)) {
    throw genericError({ message: 'メールリンクのURLが期限切れ、または不正です' });
  }

  const user = getAuth().currentUser;
  if (!user) {
    throw genericError({ message: '認証に失敗しました。もう一度試してください。' });
  }

  await asyncRetry(
    async () => {
      await updatePassword(getAuth().currentUser!, password);
    },
    { retries: 2 }
  );

  await asyncRetry(
    async () => {
      await signInWithEmailAndPassword(getAuth(), email, password);
    },
    { retries: 2 }
  );

  let idTokenResult: IdTokenResult;
  try {
    idTokenResult = await asyncRetry(async () => getAuth().currentUser!.getIdTokenResult(true), {
      retries: 2,
    });
  } catch (e) {
    throw genericError({ message: 'トークンの取得に失敗しました。もう一度試してください。' });
  }

  await asyncRetry(
    async () => {
      await fetch(apiEndpoint(`/operators/me`), {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idTokenResult.token}`,
        },
        body: JSON.stringify({ display_name: displayName, email }),
      });
    },
    { retries: 2 }
  );
}

export async function updateMyOperator(
  url: string,
  { arg }: { arg: { displayName?: string; email?: string } }
) {
  await patchFetcher(url, {
    display_name: arg.displayName,
    email: arg.email,
  });
}
